.fc {
  max-width: 100%;
  margin: 0 auto;
  background-color: white;
  border-radius: 6px 6px 0 0;
  h2 {
    display: inline-block;
  }
  table {
    thead {
      > tr {
        > td {
          box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.5) !important;
        }
      }
    }
    colgroup {
      col {
        min-width: 76px;
      }
    }
  }
  &-more-popover {
    .fc-popover-body {
      overflow: auto;
      max-height: 360px;
    }
  }
  .fc-timegrid-col.fc-day-today {
    background-color: rgba(255, 220, 40, 0.15);
  }
  &-timegrid-event-harness {
    > .fc-timegrid-event {
      min-height: 100%;
      overflow: hidden;
    }
  }
  &-cell {
    &-shaded {
      border: none !important;
    }
  }
  &-event {
    border: none !important;
    &-today {
      background-color: rgba(255, 220, 40, 0.15);
    }
  }
  &-daygrid-event {
    background: none !important;
  }
  &-highlight {
    background: rgba(0153, 0, 17, 0.2) !important;
  }
  &-button {
    padding: 0 !important;
    margin: 0 15px !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    font: bold normal 18px/70px Bebas Neue !important;
    color: #2c2c2c !important;
    text-transform: uppercase !important;
    transition: color 0.33s !important;
    @media screen and (max-width: 1200px) {
      line-height: 54px !important;
    }
    &:focus {
      box-shadow: 0 0 0 transparent !important;
      color: #2c2c2c !important;
    }
    &:hover {
      box-shadow: 0 0 0 transparent !important;
      color: #990011 !important;
    }
    &-active {
      font-weight: bold !important;
      color: #990011 !important;
    }
    &-group {
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  &-header-toolbar {
    position: sticky;
    margin-bottom: 0 !important;
    padding: 0 34px;
    min-height: 70px;
    background-color: #fffaed;
    border-radius: 6px 6px 0 0 !important;
    box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.3);
    z-index: 3;
    @media (min-width: 1201px) and (max-width: 1374px) {
      justify-content: flex-start !important;
    }
    @media screen and (max-width: 1200px) {
      justify-content: center !important;
    }
  }
  &-toolbar {
    flex-wrap: wrap;
    &-title {
      font: normal normal 18px/20px 'Proxima Nova' !important;
      color: black !important;
      text-align: center !important;
      min-width: 130px !important;
    }
    &-chunk {
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
  &-gridButton-button,
  &-listButton-button,
  &-exportButton-button,
  &-printButton-button {
    position: relative !important;
    padding-left: 18px !important;
    color: #88847e !important;
    &:before {
      position: absolute;
      top: 47%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 12px;
      height: 12px;
      background-size: 12px;
      transition: all 0.33s;
      content: '';
    }
  }
  &-gridButton-button {
    padding-right: 20px !important;
    margin-left: 0 !important;
    border-right: 1px solid #dddad4 !important;
    @media screen and (max-width: 1374px) {
      margin-right: 4px !important;
    }
    @media screen and (max-width: 992px) {
      display: none !important;
    }
    &:before {
      background: url('../../../../assets/icons/calendar-grid.svg') no-repeat center;
    }
    &:focus {
      &:before {
        background: url('../../../../assets/icons/calendar-grid.svg') no-repeat center;
      }
    }
    &:hover {
      &:before {
        background: url('../../../../assets/icons/calendar-grid-red.svg') no-repeat center;
      }
    }
  }
  &-listButton-button {
    padding-right: 20px !important;
    margin-right: 0 !important;
    @media screen and (max-width: 1374px) {
      margin-left: 4px !important;
    }
    @media screen and (max-width: 992px) {
      display: none !important;
    }
    &:before {
      background: url('../../../../assets/icons/calendar-list.svg') no-repeat center;
    }
    &:focus {
      &:before {
        background: url('../../../../assets/icons/calendar-list.svg') no-repeat center;
      }
    }
    &:hover {
      &:before {
        background: url('../../../../assets/icons/calendar-list-red.svg') no-repeat center;
      }
    }
  }
  &-exportButton-button {
    padding-left: 24px !important;
    &:before {
      width: 14px;
      height: 14px;
      background: url('../../../../assets/icons/export.svg') no-repeat center;
    }
    &:focus {
      &:before {
        background: url('../../../../assets/icons/export.svg') no-repeat center;
      }
    }
    &:hover {
      &:before {
        background: url('../../../../assets/icons/export-red.svg') no-repeat center;
      }
    }
  }
  &-printButton-button {
    padding-left: 28px !important;
    &:before {
      width: 18px;
      height: 14px;
      background: url('../../../../assets/icons/print.svg') no-repeat center;
    }
    &:focus {
      &:before {
        background: url('../../../../assets/icons/print.svg') no-repeat center;
      }
    }
    &:hover {
      &:before {
        background: url('../../../../assets/icons/print-red.svg') no-repeat center;
      }
    }
  }
  &-eventAddButton-button {
    position: relative !important;
    padding: 0 32px 0 14px !important;
    background-color: #3f444c !important;
    border-radius: 6px !important;
    font: 600 normal 12px/30px 'Proxima Nova' !important;
    color: white !important;
    &:focus,
    &:hover {
      color: white !important;
    }
    &:after {
      position: absolute !important;
      top: 9px !important;
      right: 14px !important;
      display: block;
      background: url('../../../../assets/icons/plus.svg') no-repeat center;
      width: 10px;
      height: 10px;
      content: '';
    }
  }
  &-timeGridDay-button,
  &-timeGridWeek-button,
  &-listMonth-button,
  &-listWeek-button,
  &-listDay-button,
  &-dayGridMonth-button {
    font: normal normal 16px/70px 'Proxima Nova' !important;
    color: #747474 !important;
    text-transform: none !important;
    @media screen and (max-width: 1200px) {
      line-height: 35px !important;
    }
    &.fc-button-active {
      font-weight: bold !important;
      color: #990011 !important;
    }
  }
  &-eventAddButton-button {
    white-space: nowrap !important;
  }
  &-dayGridMonth-view {
    .fc-event-title,
    .fc-event-time {
      font-weight: 600 !important;
      color: #2c2c2c !important;
    }
  }
  &-timeGridWeek-view {
    .fc-day {
      border: 2px solid #dddad4 !important;
      &.fc-col-header-cell {
        border: none !important;
      }
    }
  }
  &-col-header {
    .fc-timegrid-axis {
      border: none !important;
      font: bold normal 18px/30px 'Proxima Nova' !important;
      color: #747474 !important;
    }
    &-cell {
      height: 30px !important;
      background-color: white !important;
      border: none !important;
      border-bottom: none !important;
      font: bold normal 22px/30px 'Bebas Neue';
      color: #2c2c2c;
      text-transform: uppercase;
    }
  }
  &-timegrid {
    &-axis {
      border-color: #ddd !important;
      &-cushion {
        padding-left: 0 !important;
        padding-right: 12px !important;
        font: 600 normal 16px/20px 'Proxima Nova' !important;
        color: #747474 !important;
      }
    }
    &-slot {
      &-label-cushion {
        padding-left: 12px !important;
        padding-right: 12px !important;
        font: 600 normal 18px/20px 'Proxima Nova' !important;
        color: #747474 !important;
        letter-spacing: 0.02em !important;
      }
    }
    &-body {
      background-color: #f7f7f7;
    }
    &-col {
      position: relative;
      &:after {
        position: absolute;
        top: 0;
        right: -2px;
        display: block;
        width: 1px;
        height: 100%;
        border: 1px solid #dddad4 !important;
        z-index: 2;
        content: '';
      }
    }
  }
  &-daygrid {
    &-body {
      &-natural {
        background-color: #f7f7f7 !important;
      }
    }
    &-day {
      padding: 8px !important;
      min-height: 100% !important;
      &-frame {
        min-height: 78px !important;
      }
      &-number {
        font: 600 normal 16px/20px 'Proxima Nova';
        color: #747474;
        letter-spacing: 0.02em;
      }
    }
    &-event {
      margin-top: 2px !important;
      margin-bottom: 2px !important;
      font: 600 normal 14px/20px 'Proxima Nova' !important;
      color: #2c2c2c !important;
      letter-spacing: 0.02em !important;
      text-transform: uppercase !important;
    }
  }
  &-event {
    padding: 4px !important;
    border-radius: 6px !important;
    &-main-frame {
      padding: 4px !important;
    }
    &-title {
      font: normal normal 14px/18px 'Proxima Nova' !important;
      color: white !important;
      letter-spacing: 0.02em !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
    }
    &-time {
      font: 600 normal 14px/18px 'Proxima Nova' !important;
      color: white !important;
      letter-spacing: 0.02em !important;
    }
  }
  &-popover {
    &-header {
      background-color: #f1f1f1 !important;
    }
    &-body {
      background-color: white !important;
    }
  }
  &-list {
    overflow-x: scroll;
    &-event {
      &-time,
      &-graphic {
        display: none;
      }
      &-title {
        position: relative;
        border-top: none !important;
        @media screen and (max-width: 768px) {
          padding-right: 34px !important;
        }
        p {
          display: inline-block;
          padding-left: 14px;
          padding-right: 14px;
          margin-top: 0;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &-day {
      display: none;
    }
  }
}
.custom-event {
  position: relative;
  padding-left: 13px;
  height: 100%;
  cursor: pointer;
  border: none !important;
  &__item {
    @media screen and (min-width: 1024px) {
      flex: 0 0 calc(20% - 20px);
      max-width: calc(20% - 20px);
    }
  }
  &__block {
    display: block;
  }
  &-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__type {
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &_list {
      top: 0;
      width: 4px;
      left: 75px;
      height: 100%;
      border-radius: 0;
    }
  }
  &__text {
    margin-top: 0;
    margin-bottom: 4px;
    font: 600 normal 14px/20px 'Proxima Nova';
    color: #2c2c2c;
    letter-spacing: 0.02em;
    white-space: pre-wrap;
  }
  &__time {
    margin-top: 0;
    margin-bottom: 0;
    font: 600 normal 14px/18px 'Proxima Nova';
    color: white;
    letter-spacing: 0.02em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &_short {
      line-height: 1;
    }
    &_list {
      padding-left: 36px !important;
      min-width: 150px;
      color: #626262;
      letter-spacing: -0.02em;
    }
    &-dark {
      color: #2c2c2c;
    }
    &-sub {
      white-space: normal;
      font-weight: normal;
    }
    span {
      font-weight: normal;
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font: normal normal 14px/18px 'Proxima Nova';
    color: white;
    letter-spacing: 0.02em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &_list {
      font-weight: bold;
      color: #2c2c2c;
      @media (min-width: 769px) and (max-width: 1024px) {
        min-width: 300px;
      }
      @media screen and (max-width: 768px) {
        min-width: 200px;
        max-width: 200px;
      }
    }
  }
  &__team {
    font: 600 normal 14px/18px 'Proxima Nova';
    color: #2c2c2c;
    letter-spacing: -0.02em;
  }
  &__room {
    font: bold normal 14px/18px 'Proxima Nova';
    color: #626262;
    letter-spacing: 0.02em;
    min-width: 150px;
    @media (max-width: 800px) {
      min-width: 200px;
    }
  }
  &__estimation-link {
    position: relative;
    margin-top: 4px;
    margin-bottom: 0;
    font: 600 normal 14px/20px 'Proxima Nova';
    color: #f85f5f;
    text-transform: none;
    text-decoration: underline;
    transition: text-decoration 0.33s;
    cursor: pointer;
    svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    &_light {
      position: absolute;
      bottom: 4px;
      left: 14px;
      color: white;
    }
    &_list {
      margin: 0;
      min-width: 20px;
      min-height: 20px;
      &:hover {
        svg {
          fill: #333333;
        }
      }
      @media screen and (min-width: 1025px) {
        width: 100%;
      }
      @media screen and (max-width: 1024px) {
        position: absolute;
        right: 14px;
      }
    }
    &:focus {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__date {
    font: 600 normal 14px/16px 'Proxima Nova';
    color: #686868;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    &_list {
      & > span {
        display: block;
        &:first-child {
          font: bold normal 20px/30px 'Bebas Neue';
          color: #990011;
        }
      }
    }
  }
}
